import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    private hubspotFormId = '28e8c632-d7f2-44b2-8852-6fc8b3a6fccf';
    public formSubmitted = false;
    public errorOccurred = false;

    constructor(
        private httpClient: HttpClient
    ) {}

    ngOnInit(): void {
      throw new Error("test error");
    }

    setFormControlError(formControl) {
        formControl.setErrors({incorrect: true});
        formControl.markAsTouched();
    }

    validateEmail(email, formControl) {
        if (!email) {
            this.setFormControlError(formControl);
            return  false;
        }
        return true;
    }

    validateName(name, formControl) {
        if (!name) {
            this.setFormControlError(formControl);
            return false;
        }

        return true;
    }

    validateMessage(message, formControl) {
        if (!message) {
            this.setFormControlError(formControl);
            return false;
        }
        return true;
    }

    validateTermsAndConditions(termsAndConditions, formControl) {
        if (termsAndConditions !== true) {
            this.setFormControlError(formControl);
            return false;
        }
        return true;
    }

    validateForm(formData) {
        formData.form.markAsDirty();
        formData.form.markAsTouched();

        const validators = [
            this.validateEmail(formData.value.email, formData.form.controls.email),
            this.validateName(formData.value.name, formData.form.controls.name),
            this.validateMessage(formData.value.message, formData.form.controls.message),
            this.validateTermsAndConditions(formData.value.termsAndConditions, formData.form.controls.termsAndConditions)
        ];

        const isValid = validators.every((field) => {
            return field === true;
        });
        return isValid;
    }

    hasErrorOccurred() {
        return (this.formSubmitted && this.errorOccurred);
    }

    submitForm(formData) {
        const formValid = this.validateForm(formData);

        if (!formValid) {
            return;
        }
        const hubspotFormEndpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${environment.hubspotAccountId}/${this.hubspotFormId}`;
        const payload = {
            fields: [
                {
                    objectTypeId: '0-1',
                    name: 'email',
                    value: formData.value.email
                },
                {
                    objectTypeId: '0-1',
                    name: 'firstname',
                    value: formData.value.name
                },
                {
                    objectTypeId: '0-1',
                    name: 'message',
                    value: formData.value.message
                },
            ],
            context: {
                pageUri: `app.db3d.com/contact/`,
                pageName: 'Contact'
            },
        };

        this.httpClient.post(hubspotFormEndpoint, payload).subscribe(
            (response) => {
                this.formSubmitted = true;
            },
            (error) => {
                this.formSubmitted = true;
                this.errorOccurred = true;
            }
        );
    }
}
